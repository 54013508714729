import React, { useState } from 'react';
import { Camera, Upload, CheckCircle, AlertCircle } from 'lucide-react';

const ImageUploadCard = ({ onImageUpload, analysisResult }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setUploadError(null);
      setUploadSuccess(false);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadError('Пожалуйста, выберите изображение');
      return;
    }

    setIsUploading(true);
    setUploadError(null);
    setUploadSuccess(false);

    try {
      await onImageUpload(selectedFile);
      setUploadSuccess(true);
    } catch (error) {
      setUploadError('Ошибка при загрузке изображения');
    } finally {
      setIsUploading(false);
    }
  };

  // Format text with bold styling for content between asterisks
  const formatBoldText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        // Remove asterisks and wrap in bold element
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      return part;
    });
  };

  // Format the analysis result into a more structured display
  const formatAnalysisResult = (result) => {
    if (!result) return null;
    const content = result.choices[0].message.content;
    
    // Split content into lines and clean them up
    return content.split('\n')
      .map(line => line.trim())
      .filter(line => line.length > 0);
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 max-w-xl w-full">
      <div className="flex items-center gap-3 border-b pb-4">
        <Camera className="w-6 h-6 text-blue-500" />
        <h2 className="text-lg font-semibold">Фотография пищи</h2>
      </div>

      <div className="space-y-4 mt-4">
        {/* File Selection Area */}
        <div className="border-2 border-dashed border-gray-200 rounded-lg p-4 text-center">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileSelect}
            className="hidden"
            id="image-upload"
          />
          <label
            htmlFor="image-upload"
            className="cursor-pointer flex flex-col items-center gap-2"
          >
            <Upload className={`w-6 h-6 ${selectedFile ? 'text-green-500' : 'text-gray-400'}`} />
            <span className="text-sm text-gray-600">
              {selectedFile ? selectedFile.name : 'Выберите или перетащите файл'}
            </span>
          </label>
        </div>

        {/* Status Messages */}
        <div className="min-h-8">
          {selectedFile && !uploadError && !uploadSuccess && (
            <div className="text-green-700 text-sm flex items-center">
              <CheckCircle className="w-4 h-4 mr-2" />
              Файл выбран и готов к загрузке
            </div>
          )}
          {uploadError && (
            <div className="text-red-700 text-sm flex items-center">
              <AlertCircle className="w-4 h-4 mr-2" />
              {uploadError}
            </div>
          )}
          {uploadSuccess && (
            <div className="text-green-700 text-sm flex items-center">
              <CheckCircle className="w-4 h-4 mr-2" />
              Загрузка завершена успешно!
            </div>
          )}
        </div>

        {/* Upload Button */}
        <div className="flex justify-center">
          <button
            onClick={handleUpload}
            disabled={isUploading || !selectedFile}
            className={`px-4 py-2 rounded-md text-white text-sm ${isUploading || !selectedFile ? 'bg-gray-400' : 'bg-blue-500'}`}
          >
            {isUploading ? 'Загрузка...' : 'Загрузить изображение'}
          </button>
        </div>

        {/* Analysis Results */}
        {analysisResult && (
          <div className="mt-4 space-y-2">
            <h3 className="text-md font-semibold">Результат анализа:</h3>
            <div className="max-h-60 overflow-y-auto bg-gray-50 rounded-lg p-4">
              {formatAnalysisResult(analysisResult)?.map((line, index) => (
                <div 
                  key={index} 
                  className="text-sm text-gray-700 mb-2 last:mb-0"
                >
                  {formatBoldText(line)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUploadCard;